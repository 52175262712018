import { types } from './actions';

export const initialState = {
  step: null,
  id: null,
  responsible: null
};

const reducer = {
  [types.startAssignResponsibleWizard]: (state, action) => {
    const responsibleWizardState = state.responsibleWizard;
    return {
      ...state,
      responsibleWizard: {
        ...responsibleWizardState,
        step: 'identity',
        id: action.payload
      }
    };
  },
  [types.setCurrentStep]: (state, action) => {
    const responsibleWizardState = state.responsibleWizard;
    return {
      ...state,
      responsibleWizard: {
        ...responsibleWizardState,
        step: action.payload
      }
    };
  },
  [types.updateWizardResponsible]: (state, action) => {
    const responsibleWizardState = state.responsibleWizard;
    return {
      ...state,
      responsibleWizard: {
        ...responsibleWizardState,
        responsible: action.payload
      }
    };
  }
};

export default reducer;
