import { createAction } from 'redux-actions';
import { destroy } from 'redux-form';
import ResponsibleActions from '../actions';

const types = {
  startAssignResponsibleWizard: 'startAssignResponsibleWizard',
  stopAssignResponsibleWizard: 'stopAssignResponsibleWizard',
  setCurrentStep: 'setCurrentStep',
  updateWizardResponsible: 'updateWizardResponsible'
};

const startAssignResponsibleWizard = createAction(
  types.startAssignResponsibleWizard
);
const stopAssignResponsibleWizard = createAction(
  types.stopAssignResponsibleWizard
);
const setCurrentStep = createAction(types.setCurrentStep);
const updateWizardResponsible = createAction(types.updateWizardResponsible);

const backInResponsible = () => {
  return (dispatch, getState) => {
    const currentStep = getState().infractions.responsibleWizard.step;
    if (currentStep == null || currentStep === 'identity') {
      return; //no-op
    }
    if (currentStep === 'confirm') {
      dispatch(setCurrentStep('identity'));
    }
  };
};

const nextInResponsible = item => {
  return (dispatch, getState) => {
    const currentStep = getState().infractions.responsibleWizard.step;
    const currentInfractionId = getState().infractions.responsibleWizard.id;
    if (currentStep == null) {
      return; //no-op
    }
    if (currentStep === 'success') {
      dispatch(stopAssignResponsibleWizard());
      return;
    }
    if (currentStep === 'identity') {
      return dispatch(
        ResponsibleActions.findResponsible(item.idType, item.idNumber)
      ).then(responsible => {
        if (responsible) {
          dispatch(updateWizardResponsible(responsible));
          dispatch(setCurrentStep('confirm'));
        } else {
          console.log('TODO: mostrar mensaje de no encontrado');
        }
      });
    }
    if (currentStep === 'confirm') {
      const responsible = getState().infractions.responsibleWizard.responsible;
      dispatch(
        ResponsibleActions.assignResponsible(currentInfractionId, responsible)
      ).then(() => {
        dispatch(setCurrentStep('success'));
        dispatch(destroy('assignResponsibleForm'));
      });
    }
  };
};

/**
 * Used when the user closes the wizard without finishing.
 * That happens when closes the modal.
 */
const closeAssignResponsibleWizard = () => {
  return dispatch => {
    dispatch(destroy('assignResponsibleForm'));
    dispatch(stopAssignResponsibleWizard());
  };
};

export { types };
export default {
  startAssignResponsibleWizard,
  closeAssignResponsibleWizard,
  nextInResponsible,
  backInResponsible
};
