import { createAction } from 'redux-actions';
import { getSettings } from './selectors';

const types = {
  findCommunityByIdInProgress: 'findCommunityByIdInProgress',
  findCommunityByIdFail: 'findCommunityByIdFail',
  findCommunityByIdSuccess: 'findCommunityByIdSuccess',
  findCommunityGatesInProgress: 'findCommunityGatesInProgress',
  findCommunityGatesFail: 'findCommunityGatesFail',
  findCommunityGatesSuccess: 'findCommunityGatesSuccess',
  updateInfractionsSettingsInProgress: 'updateInfractionsSettingsInProgress',
  updateInfractionsSettingsSuccess: 'updateInfractionsSettingsSuccess',
  updateInfractionsSettingsFailed: 'updateInfractionsSettingsFailed',
  findInfractionsSettingsInProgress: 'findInfractionsSettingsInProgress',
  findInfractionsSettingsFail: 'findInfractionsSettingsFail',
  findInfractionsSettingsSuccess: 'findInfractionsSettingsSuccess'
};

const findCommunityByIdInProgress = createAction(
  types.findCommunityByIdInProgress
);
const findCommunityByIdFail = createAction(types.findCommunityByIdFail);
const findCommunityByIdSuccess = createAction(types.findCommunityByIdSuccess);
const findCommunityGatesInProgress = createAction(
  types.findCommunityGatesInProgress
);
const findCommunityGatesFail = createAction(types.findCommunityGatesFail);
const findCommunityGatesSuccess = createAction(types.findCommunityGatesSuccess);
const updateInfractionsSettingsInProgress = createAction(
  types.updateInfractionsSettingsInProgress
);
const updateInfractionsSettingsSuccess = createAction(
  types.updateInfractionsSettingsSuccess
);
const updateInfractionsSettingsFailed = createAction(
  types.updateInfractionsSettingsFailed
);
const findInfractionsSettingsInProgress = createAction(
  types.findInfractionsSettingsInProgress
);
const findInfractionsSettingsFail = createAction(
  types.findInfractionsSettingsFail
);
const findInfractionsSettingsSuccess = createAction(
  types.findInfractionsSettingsSuccess
);

const findCommunityById = id => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = null;

    const communityId = id || getState().session.profile.community.id;

    dispatch(findCommunityByIdInProgress());

    return communityModule
      .findCommunityById(communityId)
      .then(communityData => {
        dispatch(findCommunityByIdSuccess(communityData));
      })
      .catch(error => {
        dispatch(
          findCommunityByIdFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findCommunityGates = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(findCommunityGatesInProgress());

    return communityModule
      .findCommunityGates()
      .then(communityData => {
        dispatch(findCommunityGatesSuccess(communityData));
      })
      .catch(error => {
        dispatch(
          findCommunityGatesFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateInfractionsSettings = infractionsSettings => {
  return (dispatch, getState, Api) => {
    dispatch(updateInfractionsSettingsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    //const serializedInfractionsSettings = serialize(infractionsSettings);
    var settings = getSettings(getState());
    settings.fines = infractionsSettings;

    return communityModule
      .updateSettings(settings)
      .then(response => {
        dispatch(updateInfractionsSettingsSuccess(response));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          updateInfractionsSettingsFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject(error);
      });
  };
};

const findInfractionsSettings = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(findInfractionsSettingsInProgress());

    return communityModule
      .findInfractionsSettings()
      .then(communityData => {
        dispatch(findInfractionsSettingsSuccess(communityData));
      })
      .catch(error => {
        dispatch(
          findInfractionsSettingsFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  types,
  findCommunityById,
  findCommunityGates,
  updateInfractionsSettings,
  findInfractionsSettings
};
