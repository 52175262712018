import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Community Module endpoints
 */
class CommunityModule extends ApiModule {
  findCommunityById(id) {
    return this.adapter.find(`c/${id}`);
  }

  updateSettings(settings) {
    if (settings.id) {
      return this.adapter.update(`settings`, settings);
    } else {
      return this.adapter.save(`settings`, settings);
    }
  }

  findInfractionsSettings() {
    return this.adapter.find(`settings`);
  }

  /**
   * Queries the units lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryUnits(query) {
    return this.adapter.query('units', query);
  }

  findUnitById(id) {
    return this.adapter.find(`units/${id}/info`);
  }

  updateUnitById(unit) {
    return this.adapter.update(`units/${unit.id}`, unit);
  }

  createUnit(unit) {
    return this.adapter.save(`units`, unit);
  }

  deleteUnitById(id) {
    return this.adapter.delete(`units/${id}`, false);
  }

  /**
   * Queries the users list
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryUsers(query) {
    return this.adapter.query('individuals/list', query);
  }

  findUserById(id) {
    return this.adapter.find(`individuals/${id}`);
  }

  findUserByIdTypeNumber(idType, number) {
    return this.adapter.query(`individuals`, {
      idType: idType,
      number: number
    });
  }

  updateUserById(user) {
    return this.adapter.update(`individuals/${user.id}`, user);
  }

  assignUnitsToUser(userId, units) {
    return this.adapter.update(`individuals/${userId}/units`, units);
  }

  unassignUnitFromUser(userId, unitId) {
    return this.adapter.delete(`individuals/${userId}/units/${unitId}`, false);
  }

  deleteUserById(id) {
    return this.adapter.delete(`individuals/${id}`, false);
  }

  createUser(user) {
    return this.adapter.save(`individuals/create`, user);
  }

  //Create users in bulk
  uploadUsersFile(fileData) {
    return this.adapter.upload(`individuals/upload`, fileData);
  }

  startBulkUserCreationProcess(jobId) {
    return this.adapter.save(`individuals/batch`, {
      fileUri: jobId
    });
  }

  resendInvitationEmail() {
    return this.adapter.save(`individuals/emails/resend`, {});
  }

  findNationalities() {
    return this.adapter.query('nationalities', { limit: 150 });
  }

  sendWelcomeEmail(id) {
    return this.adapter.save(`individuals/emails/${id}`, {});
  }

  changeIndividualStatus(individualId, profileId, body) {
    return this.adapter.update(
      `individuals/${individualId}/profiles/${profileId}/status`,
      body
    );
  }

  findCommunityGates() {
    return this.adapter.find(`gates`);
  }

  /**
   * Queries the users list
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  search(query) {
    return this.adapter.query('individuals/list', query);
  }

  assignVehicle(individualId, unitId, vehicle) {
    const path = `individuals/${individualId}/units/${unitId}/vehicles`;
    return this.adapter.save(path, vehicle);
  }

  editVehicle(individualId, unitId, vehicleId, newData) {
    const path = `individuals/${individualId}/units/${unitId}/vehicles/${vehicleId}`;
    return this.adapter.update(path, newData);
  }

  unassignVehicle(individualId, unitId, vehicle) {
    const path = `individuals/${individualId}/units/${unitId}/vehicles/${vehicle}`;
    return this.adapter.delete(path, false);
  }

  getUserData(individualId) {
    return this.adapter.find(`individuals/${individualId}`);
  }

  getVehicleUnit(groupId) {
    const path = `groups/${groupId}`;
    return this.adapter.find(path);
  }

  getSettings() {
    return this.adapter.find('settings');
  }

  getCreatedContracts(query) {
    return this.adapter.query('contracts', query);
  }

  createContract(contractObject) {
    const path = `contracts`;
    return this.adapter.save(path, contractObject);
  }

  updateContract(profileType, individualId, contractId) {
    return this.adapter.update(
      `individuals/${individualId}/contract?profileType=${profileType}&contractId=${contractId}`
    );
  }

  validateContract(contractId, validateObject) {
    const path = `contracts/${contractId}/status`;
    return this.adapter.update(path, {
      ...validateObject,
      ownersId: [],
      tenantsId: []
    });
  }

  uploadContractFileToContract(contractId, file) {
    const path = `contracts/${contractId}`;
    return this.adapter.update(path, file);
  }

  revokeContract(contractId, reason) {
    const path = `contracts/${contractId}?reason=${reason}`;
    return this.adapter.delete(path, false);
  }

  getContractOwnersById(ownerSetValues) {
    const query = {
      individualsId: ownerSetValues
    };
    return this.adapter.query('individuals/list', query);
  }

  addCoresidents(contractId, userId) {
    return this.adapter.update(`contracts/${contractId}`, userId[0]);
  }

  updateContractDates(contractId, contract) {
    return this.adapter.update(`contracts/${contractId}`, {
      ...contract,
      ownersId: [],
      tenantsId: []
    });
  }

  uploadContract(file) {
    return this.adapter.upload(`contracts/document`, file);
  }

  sendNotification(unitId, id) {
    return this.adapter.save('notifications?type=CORRESPONDENCE', {
      unitId: unitId,
      recipientId: id
    });
  }

  addNewPetToUser(petObject) {
    const pet = petObject.pet;
    return this.adapter.save(
      `individuals/${petObject.userId}/units/${petObject.unitId}/pets`,
      pet
    );
  }

  updatePet(petObject) {
    const pet = petObject.pet;
    return this.adapter.update(
      `individuals/${petObject.userId}/units/${petObject.unitId}/pets/${petObject.petId}`,
      pet
    );
  }

  getIndividual(individualId) {
    return this.adapter.find(`groups/${individualId}`);
  }

  getIndividuals({ idType, number }) {
    return this.adapter.find(`individuals?idType=${idType}&number=${number}`);
  }

  deletePet(petId, userId, unitId) {
    return this.adapter.delete(
      `individuals/${userId}/units/${unitId}/pets/${petId}`,
      false
    );
  }

  getContracts(query) {
    const keys = Object.keys(query);
    let Query = '?';
    let i;
    for (i = 0; i < keys.length; i++) {
      Query =
        Query +
        `${keys[i]}=${query[keys[i]]}` +
        (i == keys.length - 1 ? '' : '&');
    }

    return this.adapter.find(`contracts/dates` + Query);
  }

  queryMultipleUnits(unitsIdList, limit) {
    //can be Array or string for example "234,123,454"
    const unitsIds =
      unitsIdList.length > 1 ? unitsIdList.join(',') : unitsIdList;
    return this.adapter.find(
      `units/complete?unitIds=${unitsIds}&Limit=${limit}`
    );
  }

  findByEmail(emailAddress) {
    return this.adapter.query(`individuals/email`, { emailAddress });
  }
}

export default new CommunityModule('community', ApiConfig);
