import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Crowd Module endpoints
 */
class InfractionsModule extends ApiModule {
  /**
   * Blacklist queries
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryInfractions(query) {
    return this.adapter.query('infraction', query);
  }

  assignResponsible(currentInfractionId, responsible, config) {
    var resp = {
      id: responsible.id,
      idType: responsible.personalId ? responsible.personalId.idType : null,
      idNumber: responsible.personalId ? responsible.personalId.number : null,
      portion: 100,
      firstName: responsible.firstName,
      lastName: responsible.lastName,
      email: responsible.accountInfo ? responsible.accountInfo.email : null,
      avatarUri: responsible.avatar ? responsible.avatar.uri : null,
      profiles: responsible.profiles
    };
    return this.adapter.update(`infraction/${currentInfractionId}`, {
      responsible: resp,
      config: config
    });
  }

  changeInfractionStatus(currentInfractionId, newStatus) {
    var action = newStatus === 2 ? 'cancel' : 'pay';
    return this.adapter.update(
      `infraction/${action}/${currentInfractionId}`,
      {}
    );
  }
}

export default new InfractionsModule('infractions', ApiConfig);
