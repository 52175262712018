import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import sessionReducer from './session/reducers';
import providerReducer from './providers/reducers';
import applicationReducer from './application/reducers';
import usersReducer from './users/reducers';
import unitsReducer from './units/reducers';
import communicationsReducer from './communications/reducers';
import nationalitiesReducer from './nationalities/reducers';
import movementsReducer from './movements/reducers';
import carsReducer from './cars/reducers';
import communitiesReducer from './communities/reducers';
import segmentsReducer from './segments/reducers';
import searchReducer from './search/reducers';
import blacklistReducer from './blacklist/reducers';
import infractionsReducer from './infractions/reducers';
import contractsReducer from './contracts/reducer';
import correspondenceReducer from './correspondence/reducer';
import amenitiesReducer from './amenities/reducers';
import supportToolsReducer from './supportTools/reducer';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  amenities: amenitiesReducer,
  routing: routerReducer,
  provider: providerReducer,
  session: sessionReducer,
  application: applicationReducer,
  form: formReducer,
  users: usersReducer,
  units: unitsReducer,
  communications: communicationsReducer,
  nationalities: nationalitiesReducer,
  movements: movementsReducer,
  cars: carsReducer,
  communities: communitiesReducer,
  segments: segmentsReducer,
  search: searchReducer,
  blacklist: blacklistReducer,
  infractions: infractionsReducer,
  contracts: contractsReducer,
  correspondence: correspondenceReducer,
  supportTools: supportToolsReducer
});
