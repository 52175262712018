export function getCurrentCommunity(state) {
  return state.communities;
}

export function getCommunityCoordinates(state) {
  const community = getCurrentCommunity(state);
  return community.location != null
    ? community.location
    : {
        latitude: 0,
        longitude: 0
      };
}

export function getCommunityGates(state) {
  const community = getCurrentCommunity(state);
  return community.gates;
}

export function getSettings(state) {
  const community = getCurrentCommunity(state);
  return community.settings != null
    ? community.settings
    : {
        fines: {
          unitValue: 0,
          unitName: '',
          gracePeriod: 0,
          maxWarnings: 0,
          speedRanges: [],
          history: []
        }
      };
}
