import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as ResponsibleWizardActionsTypes } from './responsibleWizard/actions';
import { types as SessionActionsTypes } from '../session/actions';
import responsibleWizardReducer, {
  initialState as responsibleWizardInitialState
} from './responsibleWizard/reducers';

const initialState = {
  findInfractionsByIdIsInProgress: false,
  findInfractionsByIdHasFailed: false,
  currentInfraction: null,
  queryInfractionsIsInProgress: false,
  queryInfractionsHasFailed: false,
  list: [],
  totalPages: -1,
  currentPage: 0,
  apiError: null,
  changeInfractionStatusInProgress: false,
  changeInfractionStatusFailed: false,
  changeInfractionStatusSuccess: false,
  responsibleWizard: responsibleWizardInitialState,
  assignResponsibleHasFailed: false,
  assignResponsibleInProgress: false
};

const reducer = handleActions(
  {
    ...responsibleWizardReducer,
    [ResponsibleWizardActionsTypes.stopAssignResponsibleWizard]: state => {
      return {
        ...state,
        responsibleWizard: responsibleWizardInitialState,
        apiError: null
      };
    },
    [types.queryInfractionsSuccess]: (state, action) => {
      const infractions = action.payload;
      return {
        ...state,
        queryInfractionsIsInProgress: false,
        queryInfractionsHasFailed: false,
        list: infractions.content,
        totalPages: infractions.totalPages,
        currentPage: infractions.number,
        apiError: null
      };
    },
    [types.queryInfractionsFailed]: (state, action) => {
      return {
        ...state,
        queryInfractionsIsInProgress: false,
        queryInfractionsHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryInfractionsInProgress]: state => {
      return {
        ...state,
        queryInfractionsIsInProgress: true,
        queryInfractionsHasFailed: false,
        apiError: null
      };
    },
    [types.findInfractionsByIdSuccess]: (state, action) => {
      return {
        ...state,
        findInfractionsByIdIsInProgress: false,
        findInfractionsByIdHasFailed: false,
        currentInfraction: action.payload,
        apiError: null
      };
    },
    [types.findInfractionsByIdIsInProgress]: state => {
      return {
        ...state,
        findInfractionsByIdIsInProgress: true,
        findInfractionsByIdHasFailed: false,
        apiError: null
      };
    },
    [types.findInfractionsByIdHasFailed]: (state, action) => {
      return {
        ...state,
        findInfractionsByIdIsInProgress: false,
        findInfractionsByIdHasFailed: true,
        currentInfraction: null,
        apiError: action.payload.apiError
      };
    },
    [types.assignResponsibleSuccess]: state => {
      return {
        ...state,
        assignResponsibleHasFailed: false,
        assignResponsibleInProgress: false,
        apiError: null
      };
    },
    [types.assignResponsibleInProgress]: state => {
      return {
        ...state,
        assignResponsibleHasFailed: false,
        assignResponsibleInProgress: true,
        apiError: null
      };
    },
    [types.assignResponsibleFailed]: (state, action) => {
      return {
        ...state,
        assignResponsibleHasFailed: true,
        assignResponsibleInProgress: false,
        apiError: action.payload.apiError
      };
    },

    [types.changeInfractionStatusInProgress]: state => {
      return {
        ...state,
        changeInfractionStatusInProgress: true,
        changeInfractionStatusFailed: false,
        changeInfractionStatusSuccess: false,
        apiError: null
      };
    },
    [types.changeInfractionStatusSuccess]: state => {
      return {
        ...state,
        changeInfractionStatusInProgress: false,
        changeInfractionStatusFailed: false,
        changeInfractionStatusSuccess: true,
        apiError: null
      };
    },
    [types.changeInfractionStatusFailed]: (state, action) => {
      return {
        ...state,
        changeInfractionStatusInProgress: false,
        changeInfractionStatusFailed: true,
        changeInfractionStatusSuccess: false,
        apiError: action.payload.apiError
      };
    },

    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
