import { createAction } from 'redux-actions';

const types = {
  queryInfractionsSuccess: 'queryInfractionsSuccess',
  queryInfractionsInProgress: 'queryInfractionsInProgress',
  queryInfractionsFailed: 'queryInfractionsFailed',
  changeInfractionStatusInProgress: 'changeInfractionStatusInProgress',
  changeInfractionStatusSuccess: 'changeInfractionStatusSuccess',
  changeInfractionStatusFailed: 'changeInfractionStatusFailed',
  assignResponsibleSuccess: 'assignResponsibleSuccess',
  assignResponsibleInProgress: 'assignResponsibleInProgress',
  assignResponsibleFailed: 'assignResponsibleFailed'
};

const queryInfractionsSuccess = createAction(types.queryInfractionsSuccess);
const queryInfractionsInProgress = createAction(
  types.queryInfractionsInProgress
);
const queryInfractionsFailed = createAction(types.queryInfractionsFailed);
const assignResponsibleSuccess = createAction(types.assignResponsibleSuccess);
const assignResponsibleInProgress = createAction(
  types.assignResponsibleInProgress
);
const assignResponsibleFailed = createAction(types.assignResponsibleFailed);

const changeInfractionStatusInProgress = createAction(
  types.changeInfractionStatusInProgress
);

const changeInfractionStatusSuccess = createAction(
  types.changeInfractionStatusSuccess
);

const changeInfractionStatusFailed = createAction(
  types.changeInfractionStatusFailed
);
/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const queryInfractions = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryInfractionsInProgress());

    const infractionsModule = Api.getModule('infractions');
    infractionsModule.adapter.token = getState().session.user.token;
    infractionsModule.adapter.communityId = getState().session.profile.community.id;

    return infractionsModule
      .queryInfractions(query)
      .then(response => {
        dispatch(queryInfractionsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryInfractionsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findResponsible = (idType, idNumber) => {
  return (dispatch, getState, Api) => {
    dispatch(assignResponsibleInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .findUserByIdTypeNumber(idType, idNumber)
      .then(responsibleData => {
        if (responsibleData) {
          return Promise.resolve(responsibleData);
        } else {
          return Promise.reject();
        }
      })
      .catch(error => {
        dispatch(
          assignResponsibleFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const assignResponsible = (infractionId, responsible) => {
  return (dispatch, getState, Api) => {
    dispatch(assignResponsibleInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .findInfractionsSettings()
      .then(communityData => {
        const infractionsModule = Api.getModule('infractions');
        infractionsModule.adapter.token = getState().session.user.token;
        infractionsModule.adapter.communityId = getState().session.profile.community.id;

        return infractionsModule
          .assignResponsible(infractionId, responsible, communityData.fines)
          .then(response => {
            dispatch(assignResponsibleSuccess(response));
            return Promise.resolve();
          })
          .catch(error => {
            dispatch(
              assignResponsibleFailed({
                apiError: error.apiErrors
              })
            );
            return Promise.reject();
          });
      })
      .catch(error => {
        dispatch(
          assignResponsibleFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const changeInfractionStatus = (infractionId, newStatus) => {
  return (dispatch, getState, Api) => {
    dispatch(changeInfractionStatusInProgress());

    const infractionsModule = Api.getModule('infractions');
    infractionsModule.adapter.token = getState().session.user.token;
    infractionsModule.adapter.communityId = getState().session.profile.community.id;

    return infractionsModule
      .changeInfractionStatus(infractionId, newStatus)
      .then(response => {
        dispatch(changeInfractionStatusSuccess());
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          changeInfractionStatusFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

export { types };
export default {
  queryInfractions,
  assignResponsible,
  findResponsible,
  changeInfractionStatus
};
